<template>
    <div class="col-sm" id="iconic-form">
        <div class="login-container">
            <table>
                <caption class="login-caption">
                    <Image srcVal="icon.png" classVal="caption-login-image" idVal="" titleVal="Iconic" />
                    <template v-if="contentPage == 'cardMng'">
                        Card Manager Dashboard
                    </template>
                    <template v-else>
                        Store Manager Dashboard
                    </template>
                </caption>
                <tbody>
                    <tr>
                        <td>
                            <i class="ri-user-3-line icon-login"></i>
                            <input type="text" class="form-control ic-input login-input" id="iconic-user" v-on:keyup.enter="verifyLogin" :placeholder="lang.username" autocomplete="off" />
                        </td>
                    </tr>
                    <tr v-if="tenantUrl == null">
                        <td>
                            <i class="ri-folder-3-line icon-login"></i>
                            <input type="text" class="form-control ic-input login-input" id="iconic-tennant" v-on:keyup.enter="verifyLogin" placeholder="Tennant" autocomplete="off" /> 
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <i class="ri-lock-password-line icon-login"></i>
                            <input type="password" class="form-control ic-input login-input" id="iconic-password" v-on:keyup.enter="verifyLogin" placeholder="Password" autocomplete="off" />
                        </td>
                    </tr>
                    <tr>
                        <td class="contain-action">
                            <span style="visibility: hidden;" class="forgot-pwd" v-on:click="forgotPassword">{{ lang.forgot_password }}?</span>
                            <button type="button" class="btn ic-btn-medium ic-btn-green ic-btn-rounded large-button" v-on:click="verifyLogin">{{ lang.log_in }}</button>
                            <button type="button" class="btn ic-btn-small ic-btn-green ic-btn-rounded small-button" v-on:click="verifyLogin">{{ lang.log_in }}</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <transition name="fade">
                <Loader v-if="isLoading" idVal="loader-login" classVal="opacity-class" />
            </transition>
            <transition name="fade">
                <AlertBar v-if="isError" errorContent="You must fill each field before continue with login!" idVal="login-error-container" classVal="opacity-class alert ic-alert ic-alert-warning" />
            </transition>
            <transition name="fade">
                <AlertBar v-if="isErrorLogin" errorContent="The credential inserted are wrong!" idVal="login-credential-error-container" classVal="opacity-class alert ic-alert ic-alert-warning" />
            </transition>
            <transition name="fade">
                <AlertBar v-if="isEndpointError" errorContent="Impossible to contact the endpoint, please retry later!" idVal="login-endpoint-error-container" classVal="opacity-class alert ic-alert ic-alert-danger" />
            </transition>
            <transition name="fade">
                <AlertBar v-if="isEndpointInternalError" errorContent="Endpoint internal error, please retry later!" idVal="login-int-endpoint-error-container" classVal="opacity-class alert ic-alert ic-alert-danger" />
            </transition>
        </div>

        <div class="powered-footer">
            <span class="dark-color">{{ lang.powered_by }}:</span>
            <Image srcVal="logo-retex-short.png" classVal="logo-powered" idVal="" titleVal="Retex S.p.a." />
        </div>
    </div>
</template>

<script>
    //import $ from 'jquery'
    import axios from 'axios'
    import Image from './elements/Image.vue'
    import Loader from './elements/Loader.vue'
    import AlertBar from './elements/AlertBar.vue'
    import notificationService from './../js/notificationService'
    import { useGettext } from '@jshmrtn/vue3-gettext'
    import * as Utility from '../js/utility.js'

    export default {
        name: 'LoginForm',
        components: {
            Image,
            Loader,
            AlertBar
        },
        setup() { 
            const language = useGettext();
            localStorage.setItem("lang", language.current);
            return {
               language,
            };
        },
        beforeMount() {
            let url_string = window.location.href;
            let url = new URL(url_string);

            let t = this.checkForTenantInHeaders();
            
            if(t == null){
                try{
                    let tenant = url.searchParams.get("tenant"); 
                    if(tenant != null){ 
                        this.tenantUrl = tenant;
                    }else{
                        this.tenantUrl = null;   
                    }

                    if(url_string.includes("?")){
                        let url_part = url_string.split("?");
                        localStorage.setItem("url_part", "?"+url_part[1]);
                    }else{
                        localStorage.setItem("url_part", "");
                    }

                }catch(Exception){
                    this.error_params = true;
                }
            }else{
                this.tenantUrl = t;
                localStorage.setItem("url_part", "");
            }

            try{ 
                if(this.env_content == "card"){ 
                    this.contentPage = "cardMng";
                    this.tenantUrl = this.card_tenant;
                }
            }catch(Exception){
                this.error_params = true;
            }
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        data() {
            return {
                lang: "",
                tenantUrl: "",
                isError: false,
                isLoading: false,
                isErrorLogin: false,
                isEndpointError: false,
                contentPage: "storeMng",
                isEndpointInternalError: false,
                card_tenant: process.env.VUE_APP_CARD_TENANT,
                env_content: process.env.VUE_APP_CONTENT, 
                siteroot: process.env.VUE_APP_SITEROOT
            }
        },
        methods: {
            forgotPassword () {
                alert('forgot password');
            },
            checkForTenantInHeaders() {
                let req = new XMLHttpRequest();
                req.open('GET', document.location, false);
                req.send(null);
                let headers = req.getAllResponseHeaders().toLowerCase();
                let headers_list = headers.split("\r\n");
                
                for(let i=0; i<headers_list.length; i++){
                    if(headers_list[i].includes("x-iconic-tenant")){
                        let tenant_part = headers_list[i].split(": ");
                        return tenant_part[1].trim();
                    }
                }    
                return null;     
            },
            closeAll () {
                this.isError = false;
                this.isLoading = false;
                this.isErrorLogin = false;
                this.isEndpointError = false;
                this.isEndpointInternalError = false;
            },
            setDisplayError(opz) {
                switch(opz){
                case "1":
                    this.isEndpointError = true;
                    completeFadeTransition('#login-endpoint-error-container');
                break;
                case "2":
                    this.isErrorLogin = true;
                    completeFadeTransition('#login-credential-error-container');
                break;
                case "3":
                    this.isEndpointInternalError = true;
                    completeFadeTransition('#login-int-endpoint-error-container');
                break;
                }
            },
            checkToOpenMessage (opz) {
                if(this.isLoading || this.isError || this.isErrorLogin || this.isEndpointError){
                    this.closeAll();

                    setTimeout(() => {
                        this.setDisplayError(opz);
                    }, 1000);

                }else{
                    this.setDisplayError(opz);
                }
            },
            verifyLogin () { 
                this.closeAll ();
                
                var user = document.getElementById('iconic-user').value;
                var pwd = document.getElementById('iconic-password').value;
                var tenant = "";
                if(this.tenantUrl != null){
                    tenant = this.tenantUrl;
                }else{
                    tenant = document.getElementById('iconic-tennant').value;
                }
                
                if(user == "" || pwd == "" || tenant == "" ){
                    this.isError = true;
                    showLoginError();
                }else{
                    if(this.isError || this.isErrorLogin || this.isEndpointError){
                        this.closeAll();

                        setTimeout(() => {
                            this.isLoading = true;
                            completeFadeTransition('#loader-login');
                        }, 1000);
                    }else{
                        this.isLoading = true;
                        completeFadeTransition('#loader-login');
                    }

                    setTimeout(() => { 
                        const postdata = {username: user, password: pwd};   
                        axios.post(process.env.VUE_APP_ENDPOINT_URL+tenant+'/auth', postdata) 
                        .then(response => { 
                            if(response.status == "error" || response.exception == "AuthenticationFailed") {
                                var opz = "";
                                if(response.error.includes("Cert or Network issue")) {
                                    opz = "1";
                                }else if(response.exception == "AuthenticationFailed"){
                                    opz = "2";
                                }else{
                                    opz = "3";
                                }

                                this.checkToOpenMessage(opz);

                            }else{ 
                                if(this.contentPage == 'cardMng' && tenant == this.card_tenant){
                                    localStorage.setItem("tenant", tenant);
                                    localStorage.setItem("user_name", response.data.data.result.name);
                                    localStorage.setItem("lang", response.data.token.user_context.lang); 

                                    document.location.href = "add_booking.html";

                                }else{
                                    localStorage.setItem("tenant", tenant);
                                    localStorage.setItem("lang", response.data.token.user_context.lang); 
                                    localStorage.setItem("store_m", response.data.token.store_manager);
                                    localStorage.setItem("categ_m", response.data.token.categ_manager); 
                                    localStorage.setItem("runner", response.data.token.store_runner);
                                    localStorage.setItem("shops", JSON.stringify(response.data.data.result.website_shops)); 
                                    localStorage.setItem("channels", JSON.stringify(response.data.data.result.channels)); 
                                    localStorage.setItem("currencies", JSON.stringify(response.data.token.currencies));

                                    /* Check to mantain */
                                    localStorage.setItem("promo_env", JSON.parse(process.env.VUE_APP_PROMO_PROVIDER)[tenant]); 
                                    /* - - - */

                                    let obj = response.data.token;
                                    if(Object.prototype.hasOwnProperty.call(obj, "channel_manager")){
                                        localStorage.setItem("channel_m", response.data.token.channel_manager);
                                    }else{
                                        localStorage.setItem("channel_m", "false");
                                    }
                            
                                    if(Object.prototype.hasOwnProperty.call(obj, "customer_manager")){
                                        localStorage.setItem("customer_m", response.data.token.customer_manager);
                                    }else{
                                        localStorage.setItem("customer_m", "false");
                                    }

                                    if(Object.prototype.hasOwnProperty.call(obj, "loco_manager")){
                                        localStorage.setItem("loco_m", response.data.token.loco_manager);
                                    }else{
                                        localStorage.setItem("loco_m", "false");
                                    }

                                    if(Object.prototype.hasOwnProperty.call(obj, "timeslot_manager")){
                                        localStorage.setItem("timeslot_m", response.data.token.timeslot_manager);
                                    }else{
                                        localStorage.setItem("timeslot_m", "false");
                                    } 

                                    if(Object.prototype.hasOwnProperty.call(obj, "kiosk_manager")){
                                        localStorage.setItem("kiosk_m", response.data.token.kiosk_manager);
                                    }else{
                                        localStorage.setItem("kiosk_m", "false");
                                    } 

                                    if(localStorage.getItem('runner') == 'true' && localStorage.getItem('categ_m') != 'true' && localStorage.getItem('store_m') != 'true'){ 
                                        document.location.href = "quequeorder.html";
                                        
                                    }else if(localStorage.getItem('categ_m') == 'true' && localStorage.getItem('runner') != 'true' && localStorage.getItem('store_m') != 'true'){
                                        document.location.href = "products.html";

                                    }else if(localStorage.getItem('categ_m') == 'true' && localStorage.getItem('runner') == 'true' && localStorage.getItem('store_m') != 'true'){
                                        document.location.href = "products.html";
                                    
                                    }else{
                                        document.location.href = "orders.html";
                                    }
                                }
                            }
                        }).catch(err => { 
                            
                            this.closeAll ();
                            if(err=="Error: Request failed with status code 503"){
                                err = "Request failed.";
                                this.checkToOpenMessage("1");
                        
                            }else if(err=="Error: Request failed with status code 500"){
                                err = "Endpoint internal error."
                                this.checkToOpenMessage("3");

                            }else if(err=="Error: Request failed with status code 401"){
                                err = "Session expired."
                                this.checkToOpenMessage("2");
                            }

                            notificationService.sendNotf({"type":"error", "message":err, "title":"ERROR"});
                        });

                    }, 2500);
                }
            }
        },
    }

    function showLoginError () {
        document.querySelector('#iconic-user').classList.add("login-error");
        document.querySelector('#iconic-tennant').classList.add("login-error");
        document.querySelector('#iconic-password').classList.add("login-error");

        completeFadeTransition('#login-error-container');
    }

    function completeFadeTransition(selector) {
        setTimeout(function(){
            document.querySelector(selector).classList.remove("opacity-class");
        }, 500);
    }
</script>

<style>
    #iconic-form {
        min-height: 590px;
    }
    div.col-sm {
        height: 100%;
    }
    .container-title-logo {
        display: none;
        height: 160px;
        margin-left: -15px;
        margin-bottom: 50px;
        width: calc(100% + 30px);
        background-color: #f3f3f3;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.14), 2px 3px 1px -2px rgba(0, 0, 0, 0.2), 2px 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .logo-top-resize {
        width: 250px;
        position: relative;
        top: 50px;
    }
    div.col-sm {
        height: 100%;
    }
    .login-container {
        width: 65%;
        margin-left: 17.5%;
        position: relative;
        top: calc(40% - 161px);
    }
    .login-container table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 15px;
    }
    .login-container table tbody tr td {
        padding: 15px;
        display: flex;
    }
    .login-caption {
        caption-side: top;
        color: #e1e1e1;
        text-shadow: 1px 0px 1px #00232D;
        font-size: 1.6rem;
        margin-left: 15px;
    }
    .caption-login-image {
        height: 23px;
        position: relative;
        top: -2px;
    }
    .icon-login {
        position: relative;
        color: #959595;
        top: 18px;
        left: 15px;
    }
    .login-input {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        font-size: 0.9rem;
        padding-left: 25px;
        border-radius: unset;
        width: calc(100% - 40px);
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .login-input::placeholder, .login-input:-ms-input-placeholder, .login-input::-ms-input-placeholder{
        color: #e1e1e1;
    }
    .login-input:focus {
        box-shadow: none;
    }
    td.contain-action {
        justify-content: space-between;
    }
    span.forgot-pwd {
        font-size: 0.8rem;
        margin-left: 15px;
        color: #959595;
        cursor: pointer;
        position: relative;
        top: 18px;
    }
    span.forgot-pwd:hover {
        text-decoration: underline;
    }
    .dark-color{
        color: #343434 !important;
    }
    .small-button {
        font-size: 0.9rem !important;
        display: none;
        width: 130px;
    }
    .login-error {
        border-color: rgba(240,22,21,.3)!important;
    }
    .opacity-class { opacity: 0; }
    #loader-login {
        margin-top: 30px;
    }

    @media screen and (min-width: 771px) and (max-width: 1263px) {
        .login-container {
            top: calc(40% - 150px);
        }
        .login-caption {
            text-shadow: 1px 0px 1px #00232D;
            font-size: 1.2rem;
        }
        .caption-login-image {
            height: 15px;
        }
        .large-button {
            display: none;
        }
        .small-button {
            display: block;
        }
        span.forgot-pwd {
            top: 10px;
        }
    }

    @media screen and (min-width: 771px) and (max-width: 1133px) {
        .login-container {
            width: calc(90% - 15px);
            margin-left: 5%;
        }
    }

    @media screen and (max-width: 770px) {
        .login-container {
            position: absolute;
            top: 50px;
        }
        .container-title-logo {
            display: none;
        }
        .container-title-logo {
            display: block;
        }
        .large-button {
            display: none;
        }
        .small-button {
            display: block;
        }
        span.forgot-pwd {
            top: 10px;
        }
    }

    @media screen and (max-width: 631px) {
        .logo-top-resize {
            float: left;
            margin-left: 10%;
        }
        .login-container {
            width: calc(90% - 10px);
            margin-left: 5%;
        }
    }

    @media screen and (max-width: 550px) {
        .container-title-logo {
            height: 90px;
        }
        .logo-top-resize {
            width: 150px;
            top: 25px;
        }
        .login-caption {
            font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 420px) {
        .login-container {
            margin-left: 5px;
        }
    }

    @media screen and (max-height: 600px) {
        .login-container {
            top: 30px;
        }
    }
</style>

<style scoped>
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-to, .fade-leave {
        opacity: 1;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 1000ms;
    }
</style>
